<template>
  <div>
    <div class="ctn"
         v-if="callbackId!=null">
      <div class="headline">
        游客入园登记
      </div>
      <div class="inform">
        <div class="title">
          填写须知
        </div>
        <div class="content">
          为保障每位游客的安全，根据相关法律和政府规定，每一位到访的游客（包括儿童）在到访之前，须进行游客信息登记，收集的信息仅用于防疫工作，感谢各位的配合！
        </div>
      </div>
      <van-form @submit="onSubmit"
                label-width="8em">
        <van-field required
                   :error="false"
                   readonly
                   disabled
                   clickable
                   name="picker"
                   :value="info.scenic"
                   label="选择所到达景区"
                   placeholder="请选择"
                   @click="showTeamPicker = true"
                   :rules="[{ required: true, message: '请选择' }]" />
        <van-popup v-model="showTeamPicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="columns"
                      @confirm="onConfirm"
                      @cancel="showTeamPicker = false" />
        </van-popup>

        <van-field :error="false"
                   readonly
                   disabled
                   required
                   clickable
                   name="datetimePicker"
                   :value="info.enterTime"
                   label="入园时间"
                   placeholder="请选择"
                   @click="showDatePicker2 = true"
                   :rules="[{ required: true, message: '请选择时间' }]" />
        <van-popup v-model="showDatePicker2"
                   position="bottom">
          <van-datetime-picker type="datetime"
                               @confirm="onDateConfirm2"
                               @cancel="showDatePicker2 = false" />
        </van-popup>

        <van-field :error="false"
                   readonly
                   disabled
                   required
                   clickable
                   name="datetimePicker"
                   :value="info.outTime"
                   label="出园时间"
                   placeholder="请选择"
                   @click="showDatePicker3 = true"
                   :rules="[{ required: true, message: '请选择时间' }]" />
        <van-popup v-model="showDatePicker3"
                   position="bottom">
          <van-datetime-picker type="datetime"
                               @confirm="onDateConfirm3"
                               @cancel="showDatePicker3 = false" />
        </van-popup>

        <van-field class="choose-num"
                   name="stepper"
                   label="选择数量"
                   :error="false"
                   required>
          <template #input>
            <van-stepper v-model="userCount"
                         integer
                         max="5"
                         disabled
                         @plus="addUser"
                         @minus="minusUser" />
          </template>
        </van-field>
        <div>
          <div v-for="(item, index) in info.list"
               :key="index"
               class="choose-item">
            <div>参观人{{ index + 1 }}:</div>
            <div>
              姓名
              <input type="text"
                     v-model="item.name"
                     :id="'input' + index"
                     @change="aaa(index)"
                     placeholder="请填写姓名"
                     disabled />
              <!-- :disabled="info.list[index].isReadonly" -->
            </div>
            <div>
              手机号码
              <input type="tel"
                     v-model="item.phone"
                     :id="'phone' + index"
                     @change="aaa(index)"
                     maxlength="11"
                     placeholder="请填写手机号码"
                     disabled />
              <!-- :disabled="info.list[index].isReadonly" -->
            </div>
            <div>
              身份证号
              <input type="number"
                     :id="'idCard' + index"
                     v-model="item.idCard"
                     @change="aaa(index)"
                     maxlength="18"
                     placeholder="请填写身份证号码"
                     disabled />
              <!-- :disabled="info.list[index].isReadonly" -->
            </div>
            <!-- <div class="mybtn"
                 v-show="info.list[index].isEdit"
                 @click="onOk(index)">
              确认
            </div>
            <div class="mybtn"
                 v-show="!info.list[index].isEdit"
                 @click="onEdit(index)">
              修改
            </div> -->
          </div>
          <!-- <div class="mybtn"
                 v-show="info.list[index].isEdit"
                 @click="onOk(index)">
              确认
            </div>
            <div class="mybtn"
                 v-show="!info.list[index].isEdit"
                 @click="onEdit(index)">
              修改
            </div> -->
        </div>
        <van-field name="radio"
                   label="是否有健康码或行程码">
          <template #input>
            <van-radio-group v-model="info.isHealthy"
                             direction="horizontal">
              <van-radio disabled
                         name="1">是</van-radio>
              <van-radio disabled
                         name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field required
                   disabled
                   :error="false"
                   v-model="info.temperature"
                   name="体温"
                   label="体温"
                   placeholder="请输入您在现场车辆体温数值"
                   :rules="[{ required: true, message: '请填写体温' }]" />
        <van-field required
                   disabled
                   :error="false"
                   v-model="info.children"
                   name="儿童数量"
                   label="儿童数量"
                   placeholder="请输入儿童数量"
                   :rules="[{ required: true, message: '请填写儿童数量' }]" />
        <van-field required
                   disabled
                   :error="false"
                   v-model="info.older"
                   name="老人数量"
                   label="老人数量"
                   placeholder="请输入老人数量"
                   :rules="[{ required: true, message: '请填写老人数量' }]" />

        <van-field readonly
                   disabled
                   clickable
                   name="area"
                   :value="`${info.provinceName}/${info.cityName}/${info.countryName}`"
                   label="地区选择"
                   placeholder="点击选择省市区"
                   @click="showArea = true" />
        <van-popup v-model="showArea"
                   position="bottom">
          <van-area :area-list="areaList"
                    @confirm="onAreaConfirm"
                    @cancel="showArea = false" />
        </van-popup>

        <van-field required
                   :error="false"
                   readonly
                   disabled
                   clickable
                   name="picker"
                   :value="info.travel"
                   label="出行方式"
                   placeholder="请选择"
                   @click="showTravelPicker = true"
                   :rules="[{ required: true, message: '请选择' }]" />
        <van-popup v-model="showTravelPicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="travelcolumns"
                      @confirm="onTravelConfirm"
                      @cancel="showTravelPicker = false" />
        </van-popup>
        <van-field required
                   disabled
                   :error="false"
                   v-if="info.travelMode==='1'"
                   v-model="info.transportation"
                   name="车牌号码"
                   label="车牌号码"
                   maxlength="7"
                   placeholder="请填写车牌号码"
                   :rules="[
          {
            pattern: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/,
            required: true,
            message: '请正确填写车牌号码',
          },
        ]" />
        <van-field required
                   disabled
                   :error="false"
                   v-model="info.phone"
                   name="联系方式"
                   label="联系方式"
                   maxlength="11"
                   type="digit"
                   placeholder="请输入联系方式"
                   :rules="[
          {
            pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
            required: true,
            message: '请正确填写联系方式',
          },
        ]" />
        <van-field name="radio"
                   label="是否有健康码或行程码">
          <template #input>
            <van-radio-group v-model="info.isHealthy"
                             direction="horizontal">
              <van-radio disabled
                         name="1">是</van-radio>
              <van-radio disabled
                         name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field required
                   disabled
                   :error="false"
                   v-model="info.temperature"
                   name="体温"
                   label="体温"
                   type="digit"
                   placeholder="请输入您在现场车辆体温数值"
                   :rules="[{ required: true, message: '请填写体温' }]" />
        <van-field required
                   disabled
                   :error="false"
                   v-model="info.children"
                   name="儿童数量"
                   label="儿童数量"
                   type="digit"
                   placeholder="请输入儿童数量"
                   :rules="[{ required: true, message: '请填写儿童数量' }]" />
        <van-field required
                   disabled
                   :error="false"
                   v-model="info.older"
                   name="老人数量"
                   type="digit"
                   label="老人数量"
                   placeholder="请输入老人数量"
                   :rules="[{ required: true, message: '请填写老人数量' }]" />

        <!-- <div style="margin: 16px 60px 0;padding:0 0 20px">
          <van-button round
                      block
                      type="info"
                      native-type="submit">提交</van-button>
        </div> -->
      </van-form>
    </div>
    <div class="ctn"
         v-else>
      <div class="headline">
        游客入园登记
      </div>
      <div class="inform">
        <div class="title">
          填写须知
        </div>
        <div class="content">
          为保障每位游客的安全，根据相关法律和政府规定，每一位到访的游客（包括儿童）在到访之前，须进行游客信息登记，收集的信息仅用于防疫工作，感谢各位的配合！
        </div>
      </div>
      <van-form @submit="onSubmit"
                label-width="8em">
        <van-field required
                   :error="false"
                   readonly
                   clickable
                   name="picker"
                   :value="scenic"
                   label="选择所到达景区"
                   placeholder="请选择"
                   @click="showTeamPicker = true"
                   :rules="[{ required: true, message: '请选择' }]" />
        <van-popup v-model="showTeamPicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="columns"
                      @confirm="onConfirm"
                      @cancel="showTeamPicker = false" />
        </van-popup>

        <van-field :error="false"
                   readonly
                   required
                   clickable
                   name="datetimePicker"
                   :value="form.enterTime"
                   label="入园时间"
                   placeholder="请选择"
                   @click="showDatePicker2 = true"
                   :rules="[{ required: true, message: '请选择时间' }]" />
        <van-popup v-model="showDatePicker2"
                   position="bottom">
          <van-datetime-picker type="datetime"
                               @confirm="onDateConfirm2"
                               @cancel="showDatePicker2 = false" />
        </van-popup>

        <van-field :error="false"
                   readonly
                   required
                   clickable
                   name="datetimePicker"
                   :value="form.outTime"
                   label="出园时间"
                   placeholder="请选择"
                   @click="showDatePicker3 = true"
                   :rules="[{ required: true, message: '请选择时间' }]" />
        <van-popup v-model="showDatePicker3"
                   position="bottom">
          <van-datetime-picker type="datetime"
                               @confirm="onDateConfirm3"
                               @cancel="showDatePicker3 = false" />
        </van-popup>

        <van-field class="choose-num"
                   name="stepper"
                   label="选择数量"
                   :error="false"
                   required>
          <template #input>
            <van-stepper v-model="userCount"
                         integer
                         max="5"
                         @plus="addUser"
                         @minus="minusUser" />
          </template>
        </van-field>
        <div>
          <div v-for="(item, index) in userCount"
               :key="index"
               class="choose-item">
            <div>参观人{{ index + 1 }}:</div>
            <div>
              姓名
              <input type="text"
                     :id="'input' + index"
                     @change="aaa(index)"
                     placeholder="请填写姓名"
                     :disabled="form.list[index].isReadonly" />
            </div>
            <div>
              手机号码
              <input type="tel"
                     :id="'phone' + index"
                     @change="aaa(index)"
                     maxlength="11"
                     placeholder="请填写手机号码"
                     :disabled="form.list[index].isReadonly" />
            </div>
            <div>
              身份证号
              <input type="text"
                     :id="'idCard' + index"
                     @change="aaa(index,'3')"
                     maxlength="18"
                     placeholder="请填写身份证号码"
                     :disabled="form.list[index].isReadonly" />
            </div>
            <div class="mybtn"
                 v-show="form.list[index].isEdit"
                 @click="onOk(index)">
              确认
            </div>
            <div class="mybtn"
                 v-show="!form.list[index].isEdit"
                 @click="onEdit(index)">
              修改
            </div>
          </div>
        </div>

        <van-field readonly
                   clickable
                   name="area"
                   :value="placeValue"
                   label="地区选择"
                   placeholder="点击选择省市区"
                   @click="showArea = true" />
        <van-popup v-model="showArea"
                   position="bottom">
          <van-area :area-list="areaList"
                    @confirm="onAreaConfirm"
                    @cancel="showArea = false" />
        </van-popup>

        <van-field required
                   :error="false"
                   readonly
                   clickable
                   name="picker"
                   :value="travel"
                   label="出行方式"
                   placeholder="请选择"
                   @click="showTravelPicker = true"
                   :rules="[{ required: true, message: '请选择' }]" />
        <van-popup v-model="showTravelPicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="travelcolumns"
                      @confirm="onTravelConfirm"
                      @cancel="showTravelPicker = false" />
        </van-popup>
        <van-field required
                   v-if="travel==='自驾'"
                   :error="false"
                   v-model="form.transportation"
                   name="车牌号码"
                   label="车牌号码"
                   maxlength="7"
                   placeholder="请填写车牌号码"
                   :rules="[
          {
            pattern: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/,
            required: true,
            message: '请正确填写车牌号码',
          },
        ]" />
        <van-field required
                   :error="false"
                   v-model="form.phone"
                   name="联系方式"
                   label="联系方式"
                   type="tel"
                   maxlength="11"
                   placeholder="请输入联系方式"
                   :rules="[
          {
            pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
            required: true,
            message: '请正确填写联系方式',
          },
        ]" />
        <van-field name="radio"
                   label="是否有健康码或行程码">
          <template #input>
            <van-radio-group v-model="form.isHealthy"
                             direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field required
                   :error="false"
                   v-model="form.temperature"
                   name="体温"
                   label="体温"
                   type="number"
                   placeholder="请输入您在现场车辆体温数值"
                   :rules="[{ required: true, message: '请填写体温' }]" />
        <van-field required
                   :error="false"
                   v-model="form.children"
                   name="儿童数量"
                   type="digit"
                   label="儿童数量"
                   placeholder="请输入儿童数量"
                   :rules="[{ required: true, message: '请填写儿童数量' }]" />
        <van-field required
                   :error="false"
                   v-model="form.older"
                   name="老人数量"
                   type="digit"
                   label="老人数量"
                   placeholder="请输入老人数量"
                   :rules="[{ required: true, message: '请填写老人数量' }]" />

        <div style="margin: 16px 60px 0;padding:0 0 20px">
          <van-button round
                      block
                      type="info"
                      native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>

</template>

<script>
import { areaList } from "@vant/area-data";
import { Dialog } from 'vant';
export default {
  data () {
    return {
      // 表单
      form: {
        //   景区 字典值
        scenicId: "",
        //  入园时间
        enterTime: "",
        //  出园时间
        outTime: "",
        // 全部游客信息
        list: [
          { name: "", phone: "", idCard: "", isEdit: true, isReadonly: false },
        ],
        // 省
        province: "",
        // 市
        city: "",
        // 县
        country: "",
        //  省名
        provinceName: "",
        // 市名
        cityName: "",
        // 县名
        countryName: "",
        // 出行方式 字典值
        travelMode: "",
        // 车牌号码
        transportation: "",
        // 联系方式
        phone: "",
        // 是否有健康码或行程码
        isHealthy: "",
        // 体温
        temperature: "",
        // 儿童数量
        children: null,
        // 老人数量
        older: null,
        // 路线
        travelRoute: "",
        //   游览时间
        travelTime: "",
        //   入住酒店
        hotelId: "",
        //   负责人姓名
        directorName: "",
        // 负责人联系方式
        directorPhone: "",
        // 游客数量
        visitorsCount: null,
      },
      //   景区 显示label
      scenic: "",
      //   景区下拉选
      selectList: [],
      columns: [],
      // 出行  显示label
      travel: "",
      // 出行下拉选
      travelList: [],
      travelcolumns: [],
      // 游客数量
      // 数量步进器
      userCount: 1,
      // 游客个人信息
      object: {},
      // 游客信息编辑状态
      // isEdit: true,
      // isReadonly: false,
      //  始发地
      placeValue: "",
      showArea: false,
      areaList, // 数据格式见 Area 组件文档
      showTravelPicker: false,
      showTeamPicker: false,
      showDatePicker: false,
      showDatePicker2: false,
      showDatePicker3: false,
      info: '',
      callbackId: null,
      fullPath: '',
      showTripMode: true
    };
  },
  created () {
    this.getSelectList();
    this.info = JSON.parse(this.$route.params.info)
    this.callbackId = this.info.id
    // console.log(this.$route.params.info)
    console.log(this.info, this.callbackId);
    // console.log(this.selectList, this.columns, '$$$$$$$')
  },
  methods: {
    aaa (index, h) {
      let a = document.getElementById("input" + index).value;
      // console.log("a", a);
      let b = document.getElementById("phone" + index).value;
      // console.log("a", b);
      let c = document.getElementById("idCard" + index).value;
      // console.log("a", c);
      if (h === '3')
      {
        var idcardReg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$)/;
        if (!idcardReg.test(c))
        {
          Dialog.alert({
            message: '请输入正确格式的身份证号',
            confirmButtonColor: '#1989fa'
          }).then(() => {
            // on close
          });
          this.object = {}
        } else
        {
          this.object = {
            name: a,
            phone: b,
            idCard: c,
            isEdit: true,
            isReadonly: false,
          };
        }
      }

    },
    addUser () {
      this.form.list.push({
        name: "",
        phone: "",
        idCard: "",
        isEdit: true,
        isReadonly: false,
      })
    },
    minusUser () {
      this.form.list.pop()
    },
    getSelectList () {
      //下拉选
      this.$axios2.get(`/registors/zhRegistors/selectedList`).then((res) => {
        console.log(res.data.result, '46546465465');
        if (res.data.code === 200)
        {
          // 景区
          this.selectList = res.data.result.scenic;
          // console.log(this.selectList, '*******')
          this.columns = this.selectList.map((item) => {
            return item.text;
          });
          // 出行方式
          this.travelList = res.data.result.travelMode;
          this.travelcolumns = this.travelList.map((item) => {
            return item.text;
          });
          if (this.callbackId != null)
          {
            this.selectList.map((item) => {
              if (this.info.scenicId === item.value)
              {
                this.info.scenic = item.text;
                // console.log(this.info.scenic, '546546454645')
              }
            });
            this.travelList.map((item) => {
              if (this.info.travelMode === item.value)
              {
                this.info.travel = item.text;
                // console.log(this.info.travel, '546546454645')
              }
            });
          }
        }
      });
    },
    onSubmit (values) {
      console.log(values)
      const { data: res } = this.$axios2.post(
        `/registors/zhRegistors/addVisitors`,
        this.form
      ).then(info => {
        // console.log(info)
        this.$router.push({ name: 'code', params: { id: `${info.data.result.id}`, classify: `2` } })
      })
      console.log("res", res);
      console.log("submit", values);
    },

    onOk (index) {
      // console.log("isedit", this.form.list[index].isEdit);
      if (this.object.name && this.object.phone && this.object.idCard)
      {
        this.object.isEdit = false;
        this.object.isReadonly = true;
        let people = {
          name: "",
          phone: "",
          idCard: "",
          isEdit: false,
          isReadonly: true,
        };
        people.name = this.object.name;
        people.phone = this.object.phone;
        people.idCard = this.object.idCard;
        if (this.form.list[index])
        {
          console.log("bbb");
          console.log("object", this.object);
          console.log("index", index);
          this.form.list.splice(index, 1, people);
          console.log("list", this.form.list);
        } else
        {
          console.log("aaa");
          this.form.list.push(people);
        }
        people = {
          name: "",
          phone: "",
          idCard: "",
          isEdit: false,
          isReadonly: true,
        };
      } else
      {
        Dialog.alert({
          message: '认真填写',
          confirmButtonColor: '#1989fa'
        }).then(() => {
          // on close
        });
      }
      console.log("oklist", this.form.list);
    },
    onEdit (index) {
      console.log("index", index);
      console.log("editlist", this.form.list);
      console.log("item", this.form.list[index]);

      this.form.list[index].isEdit = true;
      this.form.list[index].isReadonly = false;
    },

    onAreaConfirm (values) {
      console.log(values, '%%%%%%%%%%');
      // 省
      (this.form.provinceName = values[0].name),
        // 市
        (this.form.cityName = values[1].name),
        // 县
        (this.form.countryName = values[2].name),
        //  省名
        (this.form.province = values[0].code),
        // 市名
        (this.form.city = values[1].code),
        // 县名
        (this.form.country = values[2].code),
        (this.placeValue = values
          .filter((item) => !!item)
          .map((item) => item.name)
          .join("/"));
      this.showArea = false;
    },
    onConfirm (value) {
      this.selectList.map((item) => {
        if (item.text === value)
        {
          this.form.scenicId = item.value;
        }
      });
      this.scenic = value;
      this.showTeamPicker = false;
    },

    onTravelConfirm (value) {
      console.log(value)
      this.travelList.map((item) => {
        if (item.text === value)
        {
          this.form.travelMode = item.value;
        }
      });
      this.travel = value;
      // console.log(this.travel)
      if (value === '大巴')
      {
        this.showTripMode = false
      }
      this.showTravelPicker = false;
    },
    onDateConfirm (value) {
      var date = new Date(+value + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
      this.form.travelTime = date;
      this.showDatePicker = false;
    },
    onDateConfirm2 (value) {
      var date = new Date(+value + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
      this.form.enterTime = date;
      this.showDatePicker2 = false;
    },
    onDateConfirm3 (value) {
      var date = new Date(+value + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
      this.form.outTime = date;
      this.showDatePicker3 = false;
    },
  },
  mounted () {
    window.fullPath = window.location.href
    console.log(window.fullPath, '213213545', window.location)
  },
};
</script>

<style lang="less">
.van-dialog__content--isolated {
  min-height: 92px;
  .van-dialog__message {
    color: #bdbfc3;
    font-size: 16px;
    padding: 20px 16px;
  }
}
.ctn {
  padding: 0 14px;
  background-color: #f2f2f2;
  .headline {
    text-align: center;
    padding: 16px 0;
    font-size: 22px;
  }
  .van-button {
    height: 40px;
    font-size: 15px;
  }
  .inform {
    padding: 18px;
    background-color: #d7d7d7;
    .title {
      padding-bottom: 8px;
    }
  }
  .van-cell {
    background: none;
    flex-direction: column;
    .van-field__value {
      margin-top: 6px;
      input {
        border-radius: 4px;
        height: 30px;
        padding: 0 8px;
        background-color: #fff !important;
      }
      .van-field__body {
        padding: 0 8px;
        background-color: #fff;
        border-radius: 4px;
        .van-radio-group {
          width: 100%;
          flex-direction: column;
          margin: 8px 0;
          .van-radio--horizontal {
            margin-right: 0;
            &:not(:first-child) {
              border-top: 1px solid #bdbfc3;
              margin-top: 6px;
              padding-top: 6px;
            }
          }
        }
      }
    }
    .van-field__label {
      white-space: nowrap;
    }
  }
}
.choose-num {
  flex-direction: row !important;
  background: none;
  .van-field__body,
  .van-field__value {
    background: none !important;
    margin-top: 0 !important;
  }
  .van-cell__value {
    display: flex;
    flex-grow: 0;
    flex-basis: auto;
    margin-left: auto;
  }
  button {
    width: 20px;
    height: 20px;
    background-color: #fff;
  }
  input {
    width: 26px;
    border-radius: 0 !important;
    height: 20px !important;
    background-color: #fff;
  }
}
.choose-item {
  margin: 12px 14px;
  background-color: #fff;
  padding: 10px 18px 16px;
  border-radius: 10px;
  &:first-child {
    margin-top: 0;
  }
  .mybtn {
    display: block;
    background-color: #1989fa;
    color: #fff;
    border-radius: 66px;
    padding: 6px 0;
    margin: 16px auto 0;
    width: 100px;
    text-align: center;
    border-bottom: 0;
  }
  div {
    display: flex;
    font-size: 12px;
    align-items: center;
    padding: 4px 0;
    border-bottom: 1px solid #bdbfc3;
    &:first-child {
      margin-top: 0;
      border-bottom: 0;
    }
    input {
      flex-basis: 75%;
      margin-left: auto;
      height: 24px;
      padding: 0 8px;
    }
    input[disabled] {
      background-color: #fbfbfb;
    }
    input::-webkit-input-placeholder {
      color: #d7d7d7;
    }
  }
}
</style>
